import React, { useState } from 'react';
import { useTimeOnPage } from '../analytics';
import yted from '../api/lfApi';
import LoadingButton from '../components/LoadingButton';

function LoginPage({ fetchProfile }) {
    useTimeOnPage('loginPage', 1);

    const [childCred, setChildCred] = useState({ username: '', password: '' });
    const [isChildLoggingIn, setIsChildLoggingIn] = useState(false);
    const [loginError, setLoginError] = useState('');

    async function handleChildLogin(event) {
        if (event)
            event.preventDefault();

        if (!childCred.username || !childCred.password) {
            return;
        }

        setIsChildLoggingIn(true);
        setLoginError('');

        try {
            const response = await yted.post('/auth/login_cred', childCred);
            if (response.status === 200) {
                if (response.data.success) {
                    await fetchProfile();
                } else {
                    setLoginError('Invalid credentials. Please try again.');
                }
            }
            else {
                setLoginError('Login failed. Please try again.');
            }
        }
        catch (error) {
            console.error('Login failed: ', error);
            setLoginError('Login failed. Please try again later.');
        }

        setIsChildLoggingIn(false);
    };

    async function handleParentLogin(event) {
        window.location.href = process.env.REACT_APP_LF_PARENT_URL;
    }

    function handleChildChange(e) {
        const { name, value } = e.target;
        setChildCred(prevState => ({ ...prevState, [name]: value }));
    };

    return <div className="flex flex-col sm:flex-row mx-auto max-w-screen-lg gap-4 my-8">
        <div className="sm:w-1/2 flex flex-col items-center bg-white border border-gray-300 rounded-2xl">
            <h2 className="text-lg m-4">Child Login</h2>
            <form onSubmit={handleChildLogin} className="flex flex-col items-center mb-6">
                <input
                    type="text"
                    className="border border-gray-300 p-1 my-1"
                    name="username"
                    placeholder="Username"
                    value={childCred.username}
                    onChange={handleChildChange} />
                <input
                    type="password"
                    className="border border-gray-300 p-1 my-1"
                    name="password"
                    placeholder="Password"
                    value={childCred.password}
                    onChange={handleChildChange} />

                {loginError && <p className="text-red-500 text-sm py-2">{loginError}</p>}
                
                <LoadingButton
                    type="submit"
                    className="bg-blue-500 text-white py-2 px-4 rounded my-2"
                    text="Login"
                    isLoading={isChildLoggingIn}
                    onClick={handleChildLogin} />
            </form>
        </div>
        <div className="sm:w-1/2 flex flex-col items-center bg-white border border-gray-300 rounded-2xl">
            <h2 className="text-lg m-4">Parent Login</h2>
            <button className="bg-blue-500 text-white py-2 px-4 rounded my-4"
                    onClick={handleParentLogin}>
                Go to parent portal
            </button>
        </div>
    </div>;
}

export default LoginPage;
