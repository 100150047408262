import React, { useState, useEffect } from 'react';
import { Routes, Route, useParams, useNavigate } from 'react-router-dom';
import { useError } from '../contexts/ErrorContext';
import StudyPlan from './StudyPlan';
import EditStudyPlan from './StudyPlanEdit';
import yted from '../api/lfApi';
import { postStudyPlan } from '../api/lfApiStudyPlan';

async function updateCurrentLessonIndex(studyPlanId, lessonIndex, topicIndex) {
    await yted.post(`/lesson_plan/lesson_index`, {
        study_plan_id: studyPlanId,
        lesson_index: lessonIndex,
        topic_index: topicIndex,
    });
};

const StudyPlanDetail = ({ profile }) => {
    const { studyPlanId } = useParams();

    const [studyPlan, setStudyPlan] = useState(null);
    const [completedVideoIdsDict, setCompletedVideoIdsDict] = useState({});
    const [backToSettings, setBackToSettings] = useState(false);
    const [isArchived, setIsArchived] = useState(false);
    const { setError } = useError();

    const navigate = useNavigate();

    const navigateToRoot = () => {
        navigate('');
    };

    const navigateToEdit = () => {
        navigate('edit');
    };

    const navigateToSettings = () => {
        navigate('/settings');
    };

    const navigateToHome = () => {
        navigate(`/feed?sp=${studyPlanId}`);
    };

    const loadStudyPlan = async (planId) => {
        const response = await yted.get(`/lesson_plan/${planId}`);

        setCompletedVideoIdsDict(response.data.completed_video_ids);
        setStudyPlan(response.data.study_plan);
    }

    const updateLessonIndex = (lessonIndex, topicIndex) => {
        const newStudyPlan = { ...studyPlan };
        if (newStudyPlan.current_position) {
            newStudyPlan.current_position.lesson_index = lessonIndex;
            newStudyPlan.current_position.topic_index = topicIndex;
        } else {
            newStudyPlan.current_topic_index = lessonIndex;
        }
        setStudyPlan(newStudyPlan);
    };

    const onLessonClick = async (lessonIndex, doCheck) => {
        const currentLessonIndex = studyPlan.current_position
            ? studyPlan.current_position.lesson_index
            : studyPlan.current_topic_index;
        if (!doCheck || lessonIndex < currentLessonIndex) {
            await updateCurrentLessonIndex(studyPlan.id, lessonIndex, 0);
            updateLessonIndex(lessonIndex, 0);
        }
    };

    const onTopicClick = async (lessonIndex, topicIndex) => {
        const currentLessonIndex = studyPlan.current_position
            ? studyPlan.current_position.lesson_index
            : studyPlan.current_topic_index;
        if (lessonIndex === currentLessonIndex) {
            await updateCurrentLessonIndex(studyPlan.id, lessonIndex, topicIndex);
            updateLessonIndex(lessonIndex, topicIndex);
        }
    };

    const onSave = async (studyPlan) => {
        const res = await postStudyPlan(profile.username, studyPlan, setError);
        if (res) {
            await onCancel();
        }
    };

    const onCancel = async () => {
        await loadStudyPlan(studyPlanId);
        navigateToRoot();
    };

    const onBack = async () => {
        if (backToSettings) {
            navigateToSettings();
        } else {
            navigateToHome();
        }
    };

    useEffect(() => {
        loadStudyPlan(studyPlanId);

        const params = new URLSearchParams(window.location.search);
        const backToSettings = params.get('backToSettings');
        setBackToSettings(backToSettings);

        const isArchived = params.get('isArchived');
        setIsArchived(isArchived === 'true');
    }, []);

    if (!studyPlan) {
        return <div>Loading...</div>;
    }

    return <div className="text-left">
        <Routes>
            <Route path="" element={
            <>
                <div className="flex justify-between mb-4">
                    <button className="text-gray-500 py-2 cursor-pointer hover:text-gray-700" 
                            onClick={onBack}>
                        ← Back
                    </button>
                    <button
                        className={`bg-blue-500 text-white py-2 px-4 rounded ${isArchived ? 'hidden' : ''}`}
                        onClick={navigateToEdit}
                    >
                        Edit study plan
                    </button>
                </div>
                <StudyPlan
                    studyPlan={studyPlan}
                    completedVideoIdsDict={completedVideoIdsDict}
                    onLessonClick={isArchived ? null : onLessonClick}
                    onTopicClick={isArchived ? null : onTopicClick}
                />
            </>
            } />
            <Route path="edit" element={
                <EditStudyPlan
                    username={profile?.username}
                    lessonPlan={studyPlan}
                    onOk={onSave}
                    onCancel={onCancel}
                />
            } />
        </Routes>
    </div>;
};

export default StudyPlanDetail;
