import React, { useState } from 'react';
import LoadingButton from './LoadingButton';
import FeedbackButton from './FeedbackButton';
import { donutChart } from '../api/donutChart';
import VideoList from './VideoList';
import { Tooltip } from './InfoTooltip';
import SearchBox from './SearchBox';
import { useNavigate } from 'react-router-dom';

function _progressStyle(videoId, progressDict) {
    const progress = progressDict[videoId];
    if (progress === undefined) {
        return 'hidden';
    } else if (progress === 100) {
        return 'w-full';
    } else {
        const progressStyle = 'w-' + Math.max(1, Math.floor(progress / 20)).toString() + '/5';
        return progressStyle;
    }
}

function _renderVideo(feedItems, video, reportedVideos, onVideoSelect) {
    const videoId = video.id.videoId;
    const { title, thumbnails, channelTitle, channelId } = video.snippet;

    return (
        <div key={videoId} className="flex-col border rounded-2xl p-3 relative !bg-white">
            {/* Thumbnail */}
            <div className="relative aspect-video rounded-xl overflow-hidden flex items-center justify-center bg-black">
                <img
                    src={thumbnails.high.url}
                    alt={title}
                    className="w-full cursor-pointer object-fill"
                    onClick={() => onVideoSelect(video, feedItems.study_plan_id, feedItems.current_position)}
                />
                <div className={`z-10 absolute bottom-0 left-0 h-1 bg-red-500 ${_progressStyle(videoId, feedItems.progress_by_video_id)}`}></div>
            </div>

            {/* Title */}
            <div className="flex-1 mt-4">
                <h4 className="font-bold cursor-pointer" onClick={() => onVideoSelect(video, feedItems.study_plan_id, feedItems.current_position)}>
                    {title}
                </h4>
                <p>{channelTitle}</p>
            </div>

            <div className="absolute bottom-0 right-0 mb-2 mr-2 flex">
                <FeedbackButton contentIds={[videoId, channelId]} data={{lesson_id: feedItems.lesson_id}} />
            </div>
        </div>
    );
}

const Feed = ({ videos: feed, onVideoSelect, onStudyPlanSelect, onNext, onPrev }) => {
    const [videoCount, setVideoCount] = useState(6);
    const [reportedVideos, setReportedVideos] = useState({});
    const [isSearchVisible, setIsSearchVisible] = useState(false);

    const navigate = useNavigate();

    const DEFAULT_VALUE = 6;

    if (!feed) {
        return <div>Loading...</div>;
    }

    const onShowMore = () => {
        setVideoCount(videoCount + DEFAULT_VALUE);
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleSearchClick = () => {
        setIsSearchVisible(true);
        scrollToTop();
    };

    const renderedTopicVideos = feed.map((feedItems, feedIndex) => {
        return <div key={feedIndex}>
            <div className="flex justify-end">
                <button
                    onClick={() => onStudyPlanSelect(feedItems.study_plan_id)}
                    className="bg-blue-500 text-white py-2 px-4 rounded mb-2 sm:mb-0"
                >
                    View study plan
                </button>
            </div>

            <h2 className="mb-4 sm:flex sm:items-center">
                <div className="flex items-center">
                    <div
                        className="text-xl font-bold cursor-pointer mr-2"
                        onClick={() => onStudyPlanSelect(feedItems.study_plan_id)}>
                        {feedItems.topic}
                    </div>
                </div>

                <div className="flex items-center gap-2">
                    {
                        feedItems.completed_video_ids.length >= feedItems.lesson_num_videos && !isSearchVisible &&
                        <div className="sm:ml-2">
                            <LoadingButton onClick={() => onNext(feedItems.study_plan_id)} text="Next lesson" />
                        </div>
                    }
                    {
                        feedItems.completed_video_ids.length < feedItems.lesson_num_videos &&
                        <div className={`relative flex items-center ${!isSearchVisible ? 'p-1' : ''}`}>
                        {
                         !isSearchVisible &&
                            <Tooltip text={`${feedItems.completed_video_ids.length} video(s) completed`}>
                                {donutChart({
                                    percentage: Math.min(
                                        100, 
                                        Math.floor(
                                            feedItems.completed_video_ids.length / 
                                            feedItems.lesson_num_videos * 100))
                                    })}
                                </Tooltip>
                            }
                        </div>
                    }
                    <div>
                        <SearchBox isSearchVisible={isSearchVisible} setSearchVisible={setIsSearchVisible} />
                    </div>
                </div>
            </h2>
            
            <div>
                <VideoList name={feedItems.study_plan_id}>
                    {feedItems.items.slice(0, videoCount).map((video) => 
                        <div key={video.id.videoId}>
                        {
                            _renderVideo(feedItems, video, reportedVideos, onVideoSelect)
                        }
                        </div>
                    )}
                </VideoList>
                {videoCount < feedItems.items.length ? (
                    <div className="flex justify-center mt-6">
                        <button 
                            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                            onClick={onShowMore}
                        >
                            Show more
                        </button>
                    </div>
                ) : (
                    <div className="flex justify-center mt-6 text-gray-600">
                        Don't see any interesting videos to watch? Try{' '}
                        <span 
                            className="text-blue-500 cursor-pointer underline ml-1"
                            onClick={handleSearchClick}
                        >
                            searching
                        </span>
                        .
                    </div>
                )}
            </div>
            <div className="flex justify-center mt-12">
                <div className="w-[266px] h-[66px] overflow-hidden">
                    <img src="https://developers.google.com/static/youtube/images/developed-with-youtube-logos-2x.png" 
                        alt="Developed with YouTube" className="w-[266px] h-auto" />
                </div>
            </div>
        </div>
    });

    return <div className="text-left">
        {renderedTopicVideos}
    </div>;
};

export default Feed;
