import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const StudyPlans = ({ 
        studyPlans, 
        onViewStudyPlan, 
        onDeleteStudyPlan, 
        onReorderStudyPlans, 
        isArchived, 
        hasSubscriptionAction = () => false 
    }) => {
    const onDragEnd = async (result) => {
        if (!result.destination) return;
        if (result.destination.index === result.source.index) return;
      
        const items = Array.from(studyPlans);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        await onReorderStudyPlans(items);
    }

    return studyPlans.length > 0
        ? <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppableTable">
                {(provided, snapshot) => (
                <table className="border border-gray-200 mt-2">
                <thead className="bg-gray-200">
                    <tr>
                    <th className="font-normal p-1">Subject</th>
                    <th className="font-normal p-1">Learning goals</th>
                    <th></th>
                    </tr>
                </thead>
                <tbody {...provided.droppableProps} ref={provided.innerRef}>
                    {
                    studyPlans.map((studyPlan, i) => (
                        <Draggable key={studyPlan.id} draggableId={studyPlan.id} index={i}>
                        {(provided) => (
                        <tr 
                            {...provided.draggableProps} {...provided.dragHandleProps} 
                            ref={provided.innerRef}
                            style={{
                            ...provided.draggableProps.style,
                            tableLayout: 'fixed'
                            }}
                            className="border border-gray-200 bg-white">
                            <td className="p-1 pr-4 whitespace-nowrap">{studyPlan.emoji} {studyPlan.subject}</td>
                            <td className="p-1 pr-6">{studyPlan.goal}</td>
                            <td className="p-1">
                                <div className="flex flex-col sm:flex-row gap-2">
                                    <button className={`bg-blue-500 text-white py-1 px-4 rounded`}
                                        onClick={() => onViewStudyPlan(studyPlan)}>
                                        View
                                    </button>
                                    {
                                        !isArchived && <button className="bg-blue-500 text-white py-1 px-4 rounded cursor-pointer"
                                        onClick={() => onDeleteStudyPlan(studyPlan)}>
                                        Archive
                                        </button>
                                    }
                                </div>
                            </td>
                        </tr>
                        )}
                        </Draggable>
                    ))
                    }
                    {provided.placeholder}
                </tbody>
                </table>
                )}
            </Droppable>
            </DragDropContext>
        : <></>;
};

export default StudyPlans;
