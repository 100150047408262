export async function processText(res, callback) {
    const reader = res.body.getReader();
    var { done, value } = await reader.read();
    var response = '';
    while (!done) {
        response += new TextDecoder().decode(value);
        callback(response);
        ({ done, value } = await reader.read());
    }
}

export async function processBytes(res, callback) {
    const reader = res.body.getReader();
    var { done, value } = await reader.read();
    while (!done) {
        callback(value);
        ({ done, value } = await reader.read());
    }
}

export function parseLines(text) {
    return text ? text.split(/\n+/).map((item) => item.trim()).filter((item) => item !== '') : [];
}
