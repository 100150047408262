import React, { useState, useEffect, useRef } from 'react';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
import { useTimeOnPage } from '../analytics';
import { useError } from '../contexts/ErrorContext';
import Feed from '../components/Feed';
import { api_get, api_post } from '../api/lfApi';
import { Fireworks } from 'fireworks-js';

function getSubscriptionActionMessage(action) {
    let message = '';
    switch (action.action) {
        case 'create_new':
        case 'create_new_has_canceled':
            message = 'Ask your parent to subscribe to continue using Learnforest AI';
            break;
        case 'end_trial':
        case 'increase_quantity':
        case 'increase_quantity_trial':
            message = 'Ask your parent to subscribe to continue using Learnforest AI';
            break;
        case 'decrease_quantity':
            break;
        case 'update_payment_past_due':
            message = 'Ask your parent to login since the Learnforest AI subscription is past due.';
            break;
        case 'update_payment_unpaid':
            message = 'Ask your parent to login since the Learnforest AI subscription is unpaid.';
            break;
        case 'none':
        default:
            break;
    }

    if (message) {
        return <div className="border border-red-500 border-2 p-6 mb-8">
            <div className="mb-4">{message}</div>
            <a href={process.env.REACT_APP_LF_PARENT_URL} 
                target="_blank" rel="noopener noreferrer"
                className="bg-blue-500 text-white py-2 px-4 rounded cursor-pointer">
                Parent login
            </a>
        </div>;
    }

    return '';
}

function VideosPage({ profile, studyPlans, subscriptionAction }) {
    useTimeOnPage('videosPage');

    const [studyPlanId, setStudyPlanId] = useState(null);
    const [keywords, setKeywords] = useState('');

    const [feedItems, setFeedItems] = useState({
        items: [],
        completed_video_ids: [],
    });
    const [isLoadingFeed, setIsLoadingFeed] = useState(true);

    const [showFireworks, setShowFireworks] = useState(false);
    const fireworksContainerRef = useRef(null);
    const fireworksInstanceRef = useRef(null);
    const audioRef = useRef(null);

    const { setError } = useError();

    const location = useLocation();
    const navigate = useNavigate();

    const updateFeedItems = (newFeedItems) => {
        setFeedItems(newFeedItems);
    }

    const onFeedPrev = async (studyPlanId) => {
        const response = await api_post(
            '/feed/prev', 
            { lesson_plan_id: studyPlanId },
            setError,
        );

        if (response !== null) {
            const res = response.data.items[0];
            updateFeedItems(res);
        }
    };
    
    const onFeedNext = async (studyPlanId) => {
        const startTime = Date.now();
        setShowFireworks(true);
    
        const response = await api_post(
            '/feed/next', 
            { lesson_plan_id: studyPlanId },
            setError,
        );

        let remainingTime = 0;
        if (response !== null) {
            const res = response.data.items[0];
            updateFeedItems(res);
            
            const responseTime = Date.now();
            remainingTime = Math.max(2000, 5000 - (responseTime - startTime));
        }

        setTimeout(() => {
            setShowFireworks(false);
        }, remainingTime);
    };

    const onVideoSelect = async (video, studyPlanId, currentPosition) => {
        const lessonId = currentPosition.lesson_id;
        const topicId = currentPosition.topic_id;
        navigate(`/video?vid=${video.id.videoId}&cid=${video.snippet.channelId}&spid=${studyPlanId}&lid=${lessonId}&tid=${topicId}`);
    };

    const onStudyPlanSelect = async (studyPlanId) => {
        navigate(`/studyPlan/${studyPlanId}`);
    };

    const fetchFeed = async (studyPlanId, keywords) => {
        setIsLoadingFeed(true);

        if (studyPlanId) {
            const response = await api_get(
                `/feed/${studyPlanId}?keywords=${keywords || ''}`,
                null,
                setError,
            );
            if (response)
                setFeedItems(response.data.items[0]);
        }

        setIsLoadingFeed(false);
    }

    useEffect(() => {
        const keywords = new URLSearchParams(location.search).get('k');
        if (keywords)
            setKeywords(keywords);

        const spid = new URLSearchParams(location.search).get('sp');
        if (spid) 
            setStudyPlanId(spid);

        if (profile)
            fetchFeed(spid, keywords);
    }, [profile, location.search]);

    useEffect(() => {
        if (showFireworks && fireworksContainerRef.current) {
            fireworksInstanceRef.current = new Fireworks(fireworksContainerRef.current, {
                autoresize: true,
                opacity: 0.5,
                acceleration: 1.05,
                friction: 0.97,
                gravity: 1.5,
                particles: 50,
                traceLength: 3,
                traceSpeed: 10,
                explosion: 5,
                intensity: 30,
                flickering: 50,
                lineStyle: 'round',
                hue: {
                    min: 0,
                    max: 360
                },
                delay: {
                    min: 30,
                    max: 60
                },
                rocketsPoint: {
                    min: 50,
                    max: 50
                },
                lineWidth: {
                    explosion: {
                        min: 1,
                        max: 3
                    },
                    trace: {
                        min: 1,
                        max: 2
                    }
                },
                brightness: {
                    min: 50,
                    max: 80
                },
                decay: {
                    min: 0.015,
                    max: 0.03
                },
                mouse: {
                    click: false,
                    move: false,
                    max: 1
                }
            });
            fireworksInstanceRef.current.start();
            
            audioRef.current = new Audio('/sound/fireworks.wav');
            audioRef.current.play();
        }

        return () => {
            if (fireworksInstanceRef.current) {
                fireworksInstanceRef.current.stop();
                fireworksInstanceRef.current = null;
            }
            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current = null;
            }
        };
    }, [showFireworks]);

    return <div>
        {showFireworks && (
            <div 
                ref={fireworksContainerRef} 
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    pointerEvents: 'none',
                    zIndex: 9999
                }}
            />
        )}
        {
            subscriptionAction.action !== 'none' && getSubscriptionActionMessage(subscriptionAction)
        }
        {
        isLoadingFeed || !studyPlans || studyPlans.length === 0
            ? <div>Loading...</div>
            : (studyPlanId
                ? <Feed 
                    videos={[feedItems]} 
                    onVideoSelect={onVideoSelect} 
                    onStudyPlanSelect={onStudyPlanSelect}
                    onPrev={onFeedPrev} 
                    onNext={onFeedNext} />
                : <Navigate to={`/feed?sp=${studyPlans[0].id}`} />
            )
        }
    </div>;
}

export default VideosPage;
